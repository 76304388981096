<template>
  <div class="accordion" role="tablist">
    <b-row class="mb-1 mt-5 px-4 mb-4 border-bottom pb-4 flex">
      <b-col md="10" cols="5">
        <div class="fs-16 fw-bold">
          <strong>لیست کارخانه ها</strong>
        </div>
      </b-col>
      <b-col md="2" cols="7">
        <b-form-input id="input-small" size="sm" placeholder="جستجو" v-model="keyword" class="rounded-lg"></b-form-input>
      </b-col>
    </b-row>
    <b-card v-for="(factory, index) in getfactories" :key="factory" no-body class="mb-1 shadow-none">
      <b-card-header header-tag="header" role="tab" class="border-0">
        <div v-b-toggle="'accordion-'+index" class="border-bottom">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center pb-3">
              <img :src="factory.logo_url" :lazy-src="factory.image_url" width="30" class="object-cover" />
              <span class="fs-12 mr-2">{{ factory.name }}</span>
            </div>
            <span class="material-icons-outlined align-middle factory-down-icon">arrow_drop_down_circle</span>
          </div>
        </div>
      </b-card-header>
      <b-collapse :id="'accordion-'+ index" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="d-flex flex-column gap-y-6 md:gap-y-5 md:!p-6 w-100">
            <div
              v-for="(product, index) in factory.products"
              :key="product"
              class="d-flex w-100 mb-4 product-box"
              :class="index + 1 < factory.products.length && ' pb-6 md:pb-5 border-b'"
            >
              <div
                class="product-img-box"
              >
                <div>
                  <img :src="product.image_url" alt="" class="object-contain" width="115"/>
                </div>
                <img :src="factory.logo_url" class="object-cover" width="30"/>
              </div>
              <div class="px-4 mt-2 w-100">
                <div class="product-title">
                  <span class="material-icons-outlined align-middle">category</span>
                  {{ product.title }}
                </div>
                <div class="product-price-title">
                  <span class="text-blue-dark-200 text-xs">{{ `بروزرسانی قیمت ${new Date(product.date_update_price).toLocaleDateString('fa-IR')}` }}</span>
                </div>
                <div class="d-md-flex d-md-flex-column justify-content-between">
                  <div class="product-price d-flex align-items-center mt-3">
                    <div v-if="product.price_discounted !== product.price" class="position-relative">
                      <div class="product-price-redline"></div>
                      <span class="product-real-price mt-1">{{
                        new Intl.NumberFormat().format(product.price)
                      }}</span>
                    </div>
                    <div class="product-discount-price mr-2">
                      <span>{{ new Intl.NumberFormat().format(product.price_discounted) }}</span>
                      <span class="fs-12 mr-1">ریال</span>
                    </div>
                  </div>
                  <div>
                    <b-button
                      variant="outline-primary"
                      class="rounded-lg w-100 mt-2"
                      :href="`https://panel.ayandehcement.com/store/confirm/0?pt=${product.title}&pid=${product.id}&fn=${factory.name}&flogo=${factory.logo_url}&fid=${factory.id}`"
                      > خرید 
                      <span class="material-icons-outlined align-middle mr-2">arrow_circle_left</span>
                      </b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
data() {
  return {
    factories: [],
    keyword: ''
  }
},
methods: {
  async getFactories() {
    try {
      const { data } = await axios.get('https://admin.ayandehcement.com/api/user/v1/shop/get_factories_and_products_client')
      this.factories = data.data
    } catch (error) {
      console.log(error);
    }
  },
  arabicToPersian (text) {
    const arabicChars = {
        'ي': 'ی',  // Arabic "yeh" to Persian "yeh"
        'ك': 'ک',  // Arabic "keh" to Persian "keh"
        'ﻻ': 'لا', // Arabic "la" to Persian "la"
        'هٔ': 'ۀ', // Arabic "heh hamza" to Persian "heh"
    };

    // Loop through the text and replace Arabic characters with Persian ones
    return text.replace(/ي|ك|ﻻ|هٔ/g, function(matched) {
        return arabicChars[matched];
    });
  }
},
mounted() {
  this.getFactories()
},
computed: {
  getfactories() {
    if (this.keyword) {
    const text = this.arabicToPersian(this.keyword)
    return this.factories.filter((obj) =>
      JSON.stringify(obj).toLowerCase().includes(text.toLowerCase())
    )
  }
  return this.factories
  },
},
}
</script>
<style scoped>
.factory-down-icon {
  color: #0073EF;
  font-size: 20px;
}
.product-box {
  padding-bottom: 20px;
  border-bottom: solid 1px #E8EBEF;
}
.product-img-box {
  background-color: #EEF0F2;
  width: 225px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.product-title {
  font-size: 16px;
}
.product-price-title {
  font-size: 12px;
  color: #95A2B7;
  margin-top: 20px;
}
.product-price-redline {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 10px;
  rotate: -6deg;
  background-color: red;
}
.product-real-price {
  font-size: 12px;
  color: #647796;
}
.product-discount-price {
  font-size: 16px;
  font-weight: 400;
  color: #183462;
}
.btn-outline-primary {
  color: #0073EF !important;
  border-color: #0073EF !important;
  border-radius: 8px !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #0073EF !important;
}
@media (max-width: 768px) {
  .product-img-box {
    width: 100%;
  }
  .product-box {
    flex-direction: column;
  }
}
</style>