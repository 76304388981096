<template>
  <div class="mobile-menu">
    <item-mobile-menu
      title="صفحه اصلی"
      link_name="Home"
      icon="home"
      :active="$route.matched[1].path == '/'"
    ></item-mobile-menu>
    <item-mobile-menu
      link_name="Faq"
      icon="question_mark"
      title="سوالات متداول"
      :active="$route.matched[1].path == '/faq'"
    ></item-mobile-menu>
    <item-mobile-menu
      link_name="ContactUs"
      icon="perm_contact_calendar"
      title="ارتباط با ما"
      :active="$route.matched[1].path == '/contact_us'"
    ></item-mobile-menu>
  </div>
</template>

<script>
import ItemMobileMenu from "@/components/ItemMobileMenu";

export default {
  name: "MobileMenu",
  components: { ItemMobileMenu },
  computed: {
    ShopActive: function () {
      if (this.$route.matched[1].path == "/shop") return true;
      return false;
    },
    HomeActive: function () {
      if (this.$route.matched[1].path == "/") return true;
      return false;
    },
  },
  mounted() {
    // console.log(this.HomeActive);
  },
};
</script>

<style scoped></style>
