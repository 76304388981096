<template>
  <b-container class="pt-5">
    <b-row>
      <b-col md="12" class="mb-3">
        <div class="text-right mb-5">
          <h2 class="yekanExBold fs-16 d-inline-block">
            اطلاعات تماس سیمان آینده
          </h2>
        </div>
        <div class="position-relative mr-70 mb-5">
          <div class="square_number_title">
            <span class="material-icons">phone</span>
            <lottie-circle></lottie-circle>
          </div>
          <div class="d-inline-block">
            <p class="text-left mb-0">
              <a
                href="tel:05435313317"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >05435313317</a
              >
              -
              <a
                href="tel:02191306630"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >02191306630</a
              >
            </p>
            <p class="text-left mb-2">
              <a
                href="tel:09120709976"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >09120709976</a
              >
            </p>
          </div>
        </div>
        <div class="position-relative mr-70" style="margin-bottom: 48px">
          <div class="square_number_title">
            <span class="material-icons">place</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <p class="mb-0 fs-12">
              دفتر مرکزی: تهران بزرگراه خرازی، برج تریتا، طبقه شش، سیمان آینده
            </p>
          </div>
        </div>
        <div class="position-relative mr-70" style="margin-bottom: 48px">
          <div class="square_number_title">
            <span class="material-icons">place</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <p class="mb-0 fs-12">
              دفتر چابهار: سیستان و بلوچستان، چابهار، منطقه آزاد، نبش میدان
              کارگر، مجتمع بیزینس سنتر چابهار، طبقه اول، واحد 102
            </p>
          </div>
        </div>
        <div class="position-relative mr-70 mb-5">
          <div class="square_number_title">
            <span class="material-icons">mail</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <a
              :href="'mailto:' + contacts.email + '?Subject=تماس با آینده'"
              class="d-block d-md-none"
            >
              <button class="btn-email">info@ayandehcement.com</button>
            </a>
            <button
              class="btn-email d-none d-md-block"
              v-clipboard:copy="contacts.email"
              v-clipboard:success="onCopy"
            >
              info@ayandehcement.com
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import LottieCircle from "./../components/LottieCircle";

export default {
  name: "ContactUs",
  data() {
    return {
      code: "",
      codeEntered: null,
      parameters: {
        fullname: null,
        email: null,
        mobile: null,
        subject: null,
        desc: null,
      },
    };
  },
  components: { LottieCircle },
  computed: {
    contacts() {
      return this.$store.state.contactUsDetail;
    },
  },
  methods: {
    onCopy() {
      this.myToast("ایمیل کپی شد!");
    },
    handleClick() {
      this.$refs.captcha.refreshCaptcha();
      this.codeEntered = this.code;
    },
    submitForm() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.codeEntered != this.code) {
            this.$custom.myToast("کد اعتبار سنجی وارد شده اشتباه است", "error");
            return false;
          }

          axios
            .post("/contact_us/store_contact", this.parameters)
            .then(({ data }) => {
              if (data.status == "success") {
                this.myAlert(
                  "اطلاعات با موفقیت ذخیره شد<br>با تشکر از تماس شما",
                  "success"
                );
                this.parameters = {
                  fullname: null,
                  email: null,
                  mobile: null,
                  subject: null,
                  desc: null,
                };
                this.codeEntered = null;
                this.errors.clear();
                this.$refs.captcha.refreshCaptcha();
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.mr-70 {
  margin-right: 70px;
}

.square_number_title {
  top: 50%;
  transform: translateY(-50%);
}

.w-100px {
  width: 100px;
}
</style>
