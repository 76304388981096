<template>
  <!--    Desktop Navbar-->
  <div class="w-100" style="position: fixed; top: 0; left: 0; z-index: 5">
    <b-navbar
      class="bd-header main-header navbar-expand-md d-none d-md-flex justify-content-between p-0"
    >
      <div>
        <router-link
          :to="{ name: 'Home' }"
          class="navbar-brand d-inline-block mr-md-4"
        >
          <img
            src="./../../assets/images/logo/logo.svg"
            alt="سیمان آینده"
            height="60px"
          />
        </router-link>
        <router-link
          :to="{ name: 'Home' }"
          class="navbar-link d-inline-block mr-md-4"
        >
        صفحه اصلی
        </router-link>
        <router-link
          :to="{ name: 'Store' }"
          class="navbar-link d-inline-block mr-md-4"
        >
         فروشگاه
         </router-link>
        <router-link
          :to="{ name: 'Faq' }"
          class="navbar-link d-inline-block mr-md-4"
        >
         سوالات متداول
        </router-link>
        <router-link
          :to="{ name: 'ContactUs' }"
          class="navbar-link d-inline-block mr-md-4"
        >
         تماس با ما 
        </router-link>
      </div>
      <div id="navbarCollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item d-flex flex-column justify-content-center px-4">
            <a href="https://panel.ayandehcement.com" target="_blank">
            <button class="btn-transparent">
              ورود
              <span class="material-icons-outlined align-middle"
                >account_circle</span
              >
            </button>
            </a>
          </li>
          <li
            class="nav-item contacts d-flex flex-column justify-content-center px-4"
          >
            <div class="mt-1">
              <span
                class="material-icons align-middle text-white b-circle fs-12 mr-2"
                >call</span
              >
              <a href="tel:02191300630" class="ml-1">02191300630</a>
            </div>
          </li>
        </ul>
      </div>
    </b-navbar>

    <b-navbar class="main-header d-md-none justify-content-between">
      <router-link :to="{ name: 'Home' }" class="navbar-brand d-inline-block">
        <img
          src="./../../assets/images/logo/logo.svg"
          alt="سیمان آینده"
          height="59px"
        />
      </router-link>

      <div class="text-white" @click="modalShow = !modalShow">
        <span class="material-icons align-middle">login</span>ورود
      </div>
    </b-navbar>

    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      title="ورود به پنل"
      hide-footer
    >
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between my-3"
      >
        <div class="panel-box">
          <h5 data-v-78fccaf4="" class="text-center mb-3">پنل کاربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url('/images/new-panel.png')"
            @mouseover="showUserPanel = true"
            @mouseleave="showUserPanel = false"
          >
            <div
              v-if="showUserPanel"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://panel.ayandehcement.com/"
                >ورود به پنل کاربری</b-button
              >
            </div>
          </div>
        </div>
        <div class="panel-box mt-5 mt-md-0">
          <h5 class="text-center mb-3">پنل باربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url('/images/old-panel.png')"
            @mouseover="showBarPanel = true"
            @mouseleave="showBarPanel = false"
          >
            <div
              v-if="showBarPanel"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://admin.ayandehcement.com/"
                >ورود به پنل باربری</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <breadcrumb v-if="$store.state.breadCrumbItems"></breadcrumb>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: { Breadcrumb },
  data() {
    return {
      modalShow: false,
      showUserPanel: false,
      showBarPanel: false,
    };
  },
};
</script>

<style scoped lang="scss">
.panel-box {
  width: 45%;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .image-box {
    width: 100%;
    min-height: 200px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px;
    border: 2px solid #0a3450;
    @media screen and (max-width: 768px) {
      min-height: 150px;
    }
  }
}
</style>
