<template>
  <div>
    <main-header></main-header>
    <!-- <right-sidebar></right-sidebar> -->
    <mobile-menu></mobile-menu>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import Header from "./partial/Header";
import Footer from "./partial/Footer";
import MobileMenu from "./partial/MobileMenu";
// import RegisterModal from "../auth/RegisterModal";
// import LoginModal from "../auth/LoginModal";
// import ForgetPassModal from "../auth/ForgetPassModal";
// import ModalBuyFromEx from "../../../components/shop/ModalBuyFromEx";
// import ModalShowInvoiceEx from "../../../components/shop/ModalShowInvoiceEx";

export default {
  components: {
    // ModalShowInvoiceEx,
    // ModalBuyFromEx,
    // ForgetPassModal,
    // LoginModal,
    // RegisterModal,
    MobileMenu,
    "main-header": Header,
    "main-footer": Footer,
  },
};
</script>
